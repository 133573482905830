import {
	Container,
	createStylesContext,
	useMultiStyleConfig,
} from "@chakra-ui/react";
import { HomePageProps } from "../../shared/HomePage/HomePage";
import { HomePageSectionRenderer } from "../../shared/HomePage/HomePageSectionRenderer";

const [HomeSectionsStylesProvider] = createStylesContext("HomeSections");

export const HomePage: ReactFC<HomePageProps> = ({
	teasers,
	crossPromoData, // TODO: will we reuse this?
	layout,
}) => {
	const allStyles = useMultiStyleConfig("HomeSections", {
		variant: "marketbrief",
	});

	return (
		<HomeSectionsStylesProvider value={allStyles}>
			<Container
				data-datocms-noindex
				sx={allStyles.outerWrapper}
				className="inner-content-section"
			>
				<HomePageSectionRenderer teasers={teasers} layout={layout} />
			</Container>
		</HomeSectionsStylesProvider>
	);
};
